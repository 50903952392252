import { getFileBlob, getFileText, getLocalFileText, getSha, octokit } from "../../Sytems/Octokit";
import {to} from "await-to-js";

declare function getOraclePys(user : string, repo : string, ref : string) : Promise<
    {
        [key : string] : {
            fnames : string[],
            file : string
        }
    }
>;

//async function getLocalOraclePys(user : string, repo : string, ref : string) : Promise<
//{
//    [key : string] : {
//        fnames : string[],
//        file : string
//    }
//}
//>{
//    const text = await getLocalFileText(`featured-contracts/${repo}/interface.json`);
//    console.log(text);
//    var contract = JSON.parse(text);
//    var contract = contract[Object.keys(contract)[0]]
//    var oraclePys : {
//        [key : string] : any
//    } = {};
//    for (let i = 0; i < contract.abi.length; i++) {
//        if (contract.abi[i].oracleFolder == undefined) {continue}
//        if (oraclePys[contract.abi[i].oracleFolder] == undefined) {
//            oraclePys[contract.abi[i].oracleFolder] = {fnames: []};
//            oraclePys[contract.abi[i].oracleFolder].file = await getLocalFileText(
//                `featured-contracts/${repo}/${contract.abi[i].oracleFolder}/oracle.py`
//            );
//    }
//        oraclePys[contract.abi[i].oracleFolder].fnames.push(contract.abi[i].name);
//    }
//    console.log(oraclePys);
//    return oraclePys;
//}

async function getLocalOraclePys(user : string, repo : string, ref : string) : Promise<
{
    [key : string] : {
        fnames : string[],
        file : string
    }
}
>{
    const text = await getLocalFileText(`featured-contracts/${repo}/oracleHashes.json`);
    var oracleHashes = JSON.parse(text);
    var oraclePys : {
        [key : string] : any
    } = {};
    for (let i = 0; i < Object.keys(oracleHashes).length; i++) {
        var oracleFolder = Object.keys(oracleHashes)[i];
        if (oraclePys[oracleFolder] == undefined) {
            oraclePys[oracleFolder] = {fnames: [oracleFolder]};
            oraclePys[oracleFolder].file = await getLocalFileText(
                `featured-contracts/${repo}/${oracleFolder}/oracle.py`
            );
        }
    }
    console.log(oraclePys);
    return oraclePys;
}


export interface DappDataI {
    name : string,
    readme : string,
    contract : string,
    oracle : {
        file : string,
        fname : string
    }[],
    appTile : string
}

export interface DappI extends Partial<DappDataI>{
    __isDapp__ : true
    id : string
    gitUrl : string
}

export const isDapp = (obj : any) : obj is DappI => {

    return obj.__isDapp__;

}

export const parseGitUrl = (url : string) : {
    owner : string |undefined,
    repo : string | undefined
}=>{


    const split = url.split("/");


    return {
        owner : split[split.length -2],
        repo : split[split.length - 1]
    }
    

}

const README = 'README.html';

export const parseName = (readme : string)=>{

    const lines = readme.split("\n");
    return lines.length ? 
                    lines[0].replace(/(\W|\s)/, "").trim()
                    : "Unnamed"


}

export const getDappName = async (dapp : DappI, onGet ? : (name : string)=>void) : Promise<string>=>{

    const {
        owner,
        repo 
    } = parseGitUrl(dapp.gitUrl);

    const [err, readme] = owner && repo ? await to(getLocalFileText(`featured-contracts/${repo}/README.md`)) : [undefined, ""]
    
    if(err) throw err;

    const name = parseName(readme||"");

    onGet && onGet(name);

    return name;

}

export const isDescriptionLine = (line : string)=>{

    return (
        line.length < 1 ||
        line[0] !== "#"
    )

}

export const getDescriptionLines = (readme : string) : [number, number]=>{

    const lines = readme.split("\n");

    if(lines.length < 2){
        return [-1, -1];
    }

    if(!isDescriptionLine(lines[1])){
        return [-1, -1];
    }

    let i = 1;
    while(i < lines.length && isDescriptionLine(lines[i])) ++i;

    return [1, i];

}

export const parseDescription = (readme : string) : string=>{

    const descriptionLines = getDescriptionLines(readme);

    return readme.split("\n").slice(descriptionLines[0], descriptionLines[1] + 1).join("\n");

}

export const getDescription = async (dapp : DappI, onGet ? : (description : string)=>void) : Promise<string>=>{

    const {
        owner,
        repo 
    } = parseGitUrl(dapp.gitUrl);
    
    const [err, readme] = owner && repo ? await to(getLocalFileText(`featured-contracts/${repo}/${README}`)) : [undefined, ""]
    
    if(err) throw err;


    const description = parseDescription(readme||"");


    onGet && onGet(description);

    return description;

}

export const SOLIDITY = "contract.sol";

export const getDappSolidityContract = async (dapp : DappI, onGet ? : (contract : string)=>void) : Promise<string>=>{

    const {
        owner,
        repo 
    } = parseGitUrl(dapp.gitUrl);
    
    const [err, contract] = owner && repo ? await to(getLocalFileText(`featured-contracts/${repo}/${SOLIDITY}`)) : [undefined, ""];

    if(err) throw err;

    onGet && onGet(contract||"");

    return contract||"";

}

export const ORACLE = "oracle.py";

export const getDappOracle = async (dapp : DappI, onGet ? : (oracle : DappDataI["oracle"])=>void) : Promise<DappDataI["oracle"]>=>{

    const {
        owner,
        repo 
    } = parseGitUrl(dapp.gitUrl);
    const _oracle = owner && repo ? await getLocalOraclePys(owner, repo, "main") : {};
    const oracle = Object.values(_oracle).map((val)=>{
        return {
            fname : val.fnames.join(", "),
            file : val.file
        }
    })
    onGet && onGet(oracle);
    return oracle;

}

export const APPTILE = "tile";

export const imageUriFromRepoAndOwner = (args : {
    owner : string,
    repo : string,
    type : string
})=>{

    return `https://raw.githubusercontent.com/${args.owner}/${args.repo}/main/${APPTILE}.${args.type}`

}

export const getLocalFileBlob = async (path : string)=>await (await fetch(path)).blob();

export const getDappImageUri = async (dapp : DappI, onGet ? : (imageUri : string)=>void) : Promise<string>=>{

    const {
        owner,
        repo 
    } = parseGitUrl(dapp.gitUrl);
    
    let uri =  "";
    let ext = "png"
    const [errorPng, result] = await to(getLocalFileBlob(`featured-contracts/${repo}/${APPTILE}.${ext}`));
    console.log(result);
    if(errorPng){
        ext = "jpg"
        const [errorJpg, result] = await to(getLocalFileBlob(`featured-contracts/${repo}/${APPTILE}.${ext}`));
        if(errorJpg){
            ext = "jpeg";
            const [errorJpeg, result] =await to(getLocalFileBlob(`featured-contracts/${repo}/${APPTILE}.${ext}`));
            if(errorJpeg){
                ext = "none";
            }
        }
    }

    uri = (ext !== "none" && owner && repo) ? 
    `featured-contracts/${repo}/${APPTILE}.jpg` // blob is always returning status 200, need to fix this post-production 
    : "./android-chrome-512x512.png";

    onGet && onGet(uri);

    return uri;

}

export const getDappReadMe = async (dapp : DappI, onGet ? : (readme : string)=>void) : Promise<string>=>{

    const {
        owner,
        repo 
    } = parseGitUrl(dapp.gitUrl);
    
    const readme = owner && repo ? await getLocalFileText(`featured-contracts/${repo}/${README}`): "";

    onGet && onGet(readme);

    return readme;

}


export const getDappInfo = async (
    dapp : DappI
)=>{

    

    const {
        owner,
        repo 
    } = parseGitUrl(dapp.gitUrl);

    return owner && repo && await octokit.rest.repos.get({
        owner : owner,
        repo : repo
    });

}
