import React, {FC, ReactElement} from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useErrorContext } from '../ErrorProvider';
import { CrashLogo } from './CrashLogo';

export type ErrorNotificationProps = {
    errorText? : string,
    style? : React.CSSProperties
}

export const ErrorNotification : FC<ErrorNotificationProps>  = ({
    errorText = "Sorry, our site encountered an error. Please try refreshing the page.", 
    style
}) =>{

    const nav = useNavigate();
    const {error} = useErrorContext();

    console.log(error);
 
    if(!error) nav("/");
 
    return (

        <div style={{
            display : "grid",
            gridTemplateRows : "5fr 1fr",
            width : "100%",
            ...style
        }}>
            <div style={{
                width : "100%",
                justifyContent : "center",
                alignContent : "center"
            }}>
                <CrashLogo style={{
                    width : "300px",
                    height : "300px",
                    margin : "auto",
                }}/>
            </div>
            <div>
                <h6>{errorText.toUpperCase()}</h6>
            </div>
        </div>

    )

}