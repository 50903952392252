import React, {FC, ReactElement} from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Skeleton from "react-loading-skeleton";
import { Github, Linkedin, EnvelopeFill } from 'react-bootstrap-icons';
import { ThroughGlassAgathocles } from '../../Glitter/Animations/ThroughGlass/ThroughGlassAgathocles';
import { GrowOnEventAchamaenid } from '../../Glitter/Animations';

/**
 * 
 * @param text 
 * @returns [name, bio, github, linkedin]
 */
export const textToBio = (text : string) : [string, string, string, string]=>{
    return text.split("\n") as [string, string, string, string];
}

export type DevBadgeProps = {
    name : string
}

export const DevBadge : FC<DevBadgeProps>  = ({
    name
}) =>{

   
    const [imageUri, setImageUri] = useState<string>(`${process.env.PUBLIC_URL}/biomass/${name}.png`);

    const [bio, setBio] = useState<[string, string, string, string] |undefined>(
        undefined
    );
    useEffect(()=>{
        fetch(`biomass/${name}.txt`)
        .then((data)=>{
            data.text().then(data=>setBio(textToBio(data)))
            .catch((err)=>err)
        }).catch((err)=>err)
    }, [])

    const [hover, setHover] = useState(false);

    return (

        <div style={{
            display : 'grid',
            gridTemplateRows : "250px 60px 80px 30px 30px",
            alignItems : "center",
            alignContent : "center",
            justifyContent : "center",
            justifyItems : "center"
        }}>
            {imageUri ? 
            <ThroughGlassAgathocles glass={hover}>
                <GrowOnEventAchamaenid grow={hover}>
                    <img 
                    onClick={()=>{window.location.href = "https://github.com/" + (bio && bio[3])}}
                    onMouseOver={()=>setHover(true)}
                    onMouseOut={()=>setHover(false)}
                    onError={()=>setImageUri(`${process.env.PUBLIC_URL}/biomass/${name}.jpg`)}
                    src={imageUri} style={{
                        borderRadius : "100%",
                        cursor : "pointer"
                    }} height="200px" width="200px"/>
                </GrowOnEventAchamaenid>
            </ThroughGlassAgathocles>
            : <Skeleton circle height="200px" width="200px"/>}
            <div>
                {bio && bio[0] ? 
                <h3>{bio[0]}</h3> : <Skeleton width="100px" count={1}/>}
            </div>
            <div style={{
                width : "220px"
            }}>
                {bio && bio[1] ? 
                <p>{bio[1]}</p> : <Skeleton width="220px" count={2}/>}
            </div>
            <div>
                {bio && bio[2] ? 
                    <div  style={{
                        display : 'flex',
                        alignContent : "center",
                        alignItems : "center"
                    }}><EnvelopeFill/>&ensp;<a href={"mailto:" + bio[2]}>{bio[2]}</a></div> : <Skeleton width="140px" count={1}/>}
            </div>
            <div>
                {bio && bio[3] ? 
                    <div style={{
                        display : 'flex',
                        alignContent : "center",
                        alignItems : "center"
                    }}>
                        <Github/>&ensp;<a href={"https://github.com/" + bio[3]}>{bio[3]}</a>
                    </div> : <Skeleton width="140px" count={1}/>}
            </div>
        </div>
    
    )

}