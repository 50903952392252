import React, {FC, ReactElement} from 'react';
import { useParams } from 'react-router';

export type RunDappProps = {}

export const RunDapp : FC<RunDappProps>  = () =>{


    const {
        owner,
        repo
    } = useParams<{
        owner : string,
        repo : string
    }>();

    window.location.href = `https://open-contracts.github.io/#/${encodeURI(owner as string)}/${encodeURI(repo as string)}`; 

    return (

        <>
        

        </>

    )

}
