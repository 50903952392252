import React, {FC, ReactElement} from 'react';
import { DesktopSizes } from '../../Theme';
import { generateNamedMember, getComponentMembers } from 'rgfm';
import { Footer } from '../../Maps/Footers';

const Members = ["Header", "Content", "Footer"]

export type MainLayoutMobileProps = {}

const MainLayoutMobile : FC<MainLayoutMobileProps>  & {
    Header : FC,
    Content : FC, 
    Footer : FC
} = ({
    children
}) =>{

    const {
        Header,
        Content,
    } = getComponentMembers(Members, children);

    return (

        <div 
        style={{
            width : "100%",
            padding : DesktopSizes.Padding.whitespacePreferred,
            // display : "grid",
            display : "flex",
            flexDirection : "column",
            gridTemplateColumns: "1fr",
            justifyContent : "center",
            justifyItems : "center"
        }}>
            <div style={{
                height : "240px"
            }}>
                {Header}
                <br/>
            </div>
            <div style={{
               paddingBottom : "40px"
            }}> 
                {Content}
            </div>
            <Footer/>
        </div>

    )

}

MainLayoutMobile.Header = generateNamedMember('Header');
MainLayoutMobile.Content = generateNamedMember("Content");
MainLayoutMobile.Footer = generateNamedMember("Footer");

export {MainLayoutMobile}