import React, {FC, ReactElement} from 'react';
import { MediaResponsive } from '../../Sytems';
import { HeaderResponsive } from '../../Maps';
import { MainLayoutDesktop, MainLayoutMobile } from '../../Layouts';
import { DEVS } from '../../Maps';
import { Devs } from './Devs';

export type DevPageProps = {}

export const DevPage : FC<DevPageProps>  = () =>{

    return (

        <MediaResponsive>
        <MediaResponsive.Desktop>
            <MainLayoutDesktop>
                <MainLayoutDesktop.Header>
                    <HeaderResponsive selected={DEVS}/>
                </MainLayoutDesktop.Header>
                <MainLayoutDesktop.Content>
                   <Devs/>
                </MainLayoutDesktop.Content>
            </MainLayoutDesktop>
        </MediaResponsive.Desktop>
        <MediaResponsive.Laptop>
            <MainLayoutDesktop>
                <MainLayoutDesktop.Header>
                    <HeaderResponsive selected={DEVS}/>
                </MainLayoutDesktop.Header>
                <MainLayoutDesktop.Content>
                    <Devs/>
                </MainLayoutDesktop.Content>
            </MainLayoutDesktop>
        </MediaResponsive.Laptop>
        <MediaResponsive.Tablet>
            <MainLayoutMobile>
                <MainLayoutMobile.Header>
                    <HeaderResponsive selected={DEVS}/>
                </MainLayoutMobile.Header>
                <MainLayoutMobile.Content>
                    <Devs/>
                </MainLayoutMobile.Content>
            </MainLayoutMobile>
        </MediaResponsive.Tablet>
        <MediaResponsive.Mobile>
            <MainLayoutMobile>
                <MainLayoutMobile.Header>
                    <HeaderResponsive selected={DEVS}/>
                </MainLayoutMobile.Header>
                <MainLayoutMobile.Content>
                    <Devs/>
                </MainLayoutMobile.Content>
            </MainLayoutMobile>
        </MediaResponsive.Mobile>
    </MediaResponsive>

    )

}