import React, {FC, ReactElement, useState} from 'react';
import { generateNamedMember, getComponentMembers } from 'rgfm';
import { MainBenchLayout } from '../../Layouts/Benches';
import { Colors, DesktopSizes } from '../../Theme';
import { NewDappBlockItem } from '../../Items/Dapp/Dapp/NewDappBlockItem';
import { useErrorContext } from '../../Error/ErrorProvider';
import { useContext } from 'react';
import { useEffect } from 'react';
import { getFileText, getLocalFileText } from '../../Sytems/Octokit';
import Skeleton from 'react-loading-skeleton';
import remarkGfm from 'remark-gfm'
import {to} from "await-to-js";
import { DappBlockItem } from '../../Items/Dapp/Dapp/DappBlockItem';
import { DappI } from '../../Items';
import { GithubMarkdownRender } from '../../Views';

const Members = ["Header", "Items"];

export type MarketBenchDesktopProps = {
    items : DappI[],
    updateDapp? : (prevId : string, dapp : DappI)=>void
}

export const MarketBenchDesktop : FC<MarketBenchDesktopProps> = ({
    children,
    items,
    updateDapp
}) =>{

    const {
        dispatch
    } = useErrorContext();

    const [[frontpage, frontpageRequested], setFrontpage] = useState<[
        string |undefined,
        boolean
    ]>([undefined, false]);
    useEffect(()=>{

        if(!frontpage && !frontpageRequested){

            setFrontpage([undefined, true])

            const getFrontPage = async ()=>{

                const [err, page] = await to(getLocalFileText("FRONTPAGE.html"))

                if(err) {
                    dispatch((state)=>{
                        return {
                            ...state,
                            error : err
                        }
                    });
                    return;
                }

                setFrontpage([page || "", true]);

            }

            getFrontPage();

        }

    })

    const nodes = items.map((item)=>{

        const _updateDapp = (item : DappI)=>{

            updateDapp && updateDapp(item.id, item);

        }

        return (
            <DappBlockItem key={item.gitUrl} dappItem={item} updateDapp={_updateDapp}/>
        )
    })

    const [hovered, setHovered] = useState(false)

    return (

        <MainBenchLayout>
            <MainBenchLayout.Header>
            </MainBenchLayout.Header>
            <MainBenchLayout.Items>
                <div style={{
                    overflow : "visible"
                }}>
                        <div style={{
                            display : "grid",
                            gridTemplateColumns : "1fr 1fr 1fr",
                            height : "100%",
                            width : "100%",
                            gap : DesktopSizes.Padding.whitespacePreferred,
                            paddingTop : DesktopSizes.Padding.whitespacePreferred,
                            paddingBottom : DesktopSizes.Padding.whitespacePreferred,
                            overflow : "visible"
                        }}>
                            {nodes}
                            <NewDappBlockItem/>
                        </div>
                        <div style={{
                            textAlign : "left"
                        }}>
                            {!frontpage && <Skeleton count={5}/>}
                            {frontpage && <GithubMarkdownRender html>
                                {frontpage}
                            </GithubMarkdownRender>}
                        </div>
                        <br/>
                 </div>
            </MainBenchLayout.Items>
        </MainBenchLayout>

    )

}