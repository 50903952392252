import React, {FC, ReactElement} from 'react';
import { useMediaQuery } from 'react-responsive';
import { Colors } from '../../Theme';
import { DevBadge } from './DevBadge';

export type DevsProps = {}

export const Devs : FC<DevsProps>  = () =>{

    const mobile = useMediaQuery({
        query : '(max-width:860px)'
    });

    return (

        <div style={{
            width : "100%",
            gap : mobile ? "none" : "5%",
            display : "grid",
            gridTemplateColumns : mobile ? "1fr" : "1fr 1fr 1fr",
            gridTemplateRows : mobile ? "repeat(minmax(0, 1fr))" : "1fr",
            color : Colors.Maintheme,
            paddingBottom : "100px" // TODO: bad patch, improve later
        }}>
            {["jonas_metzger", "krishnan_srinivasan", "liam_monninger"].map((name)=>{
                return <><DevBadge key={name} name={name}/>{mobile && <><br/><br/></>}</>
            })}
        </div>

    )

}