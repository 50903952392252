import React, {FC, ReactElement} from 'react';
import Markdown from "react-markdown";
import gfm from "remark-gfm";

export type GithubMarkdownRenderProps = React.HTMLAttributes<HTMLDivElement> & {
    children : string,
    html ? : boolean
}

export const GithubMarkdownRender : FC<GithubMarkdownRenderProps>  = (props) =>{

    return (
        props.html ? <div className="gfm" dangerouslySetInnerHTML={{__html : props.children}}/> :
        <Markdown className="gfm" plugins={[gfm]}>
            {props.children}
        </Markdown>

    )

}