import React, {FC, ReactElement, useEffect, useState} from 'react';
import { DappI, getDappName, getDappSolidityContract, getDappOracle, getDappImageUri, getDappReadMe, parseGitUrl } from '../Dapp';
import { DappMainItemSource } from './DappMainItemSource';
import Skeleton from "react-loading-skeleton";
import { GithubMarkdownRender } from '../../../Views';
import remarkGfm from 'remark-gfm'
import { DappMainItemActions } from './DappMainItemActions';
import {  } from 'react-router-dom';
import { useErrorContext } from '../../../Error/ErrorProvider';
import { DesktopSizes } from '../../../Theme';
import { read } from 'fs';

export type DappMainItemReadmeProps = {
    style ? : React.CSSProperties,
    readme : string | undefined
}

export const DappMainItemReadMe : FC<DappMainItemReadmeProps> = ({
    style,
    readme
})=>{

    return (
        <div style={{
            ...style,
            textAlign : 'left'
        }}>
            {!readme && <Skeleton width="100%" count={5}/>}
            {readme && <GithubMarkdownRender html>
                {readme}    
            </GithubMarkdownRender>}
        </div>
    )

}

export type DappMainItemInternalsProps = {
    dappItem : DappI
    style? : React.CSSProperties,
    key? : React.Key,
}

export const DappMainItemInternals : FC<DappMainItemInternalsProps>  = ({
    dappItem,
    style,
}) =>{

    return (

        <div style={{
            ...style
        }}>
            <div style={{
                display : "grid",
                gridTemplateColumns : "1fr"
            }}>
                <div style={{
                    paddingBottom : DesktopSizes.Padding.whitespacePreferred
                }}>
                    <DappMainItemActions gitUrl={dappItem.gitUrl}/>
                </div>
                <div style={{
                    paddingBottom : DesktopSizes.Padding.whitespacePreferred
                }}>
                    <DappMainItemSource 
                    contract={dappItem.contract}
                    oracles={dappItem.oracle}
                    style={{
                        height : "100%",
                        width : "100%"
                    }}/>
                </div>
                <br/>
                <hr/>
                <div>
                    <DappMainItemReadMe readme={dappItem.readme}/>
                </div>
            </div>
        </div>

    )

}

export type DappMainItemProps = {
    dappItem : DappI,
    style? : React.CSSProperties,
    key? : React.Key,
    updateDapp ? : (dapp : DappI)=>void,
    forceLoad ? : boolean
}

export const DappMainItem : FC<DappMainItemProps>  = ({
    dappItem,
    style,
    updateDapp,
    forceLoad = false
}) =>{

    console.log("Rendering dapp item: ", dappItem);

    const {
        dispatch
    } = useErrorContext();

    const [dappState, setDappState] = useState(dappItem);
    useEffect(()=>{

        if(dappState !== dappItem && updateDapp){
            updateDapp(dappState)
        }

    })

    const [[nameLoad, nameRequested], setNameLoad] = useState<[
        string|undefined,
        boolean
    ]>([
        forceLoad ? undefined : dappState.name,
        false
    ]);
    useEffect(()=>{

        if(!nameLoad && !nameRequested){
            setNameLoad([undefined, true])
            getDappName(
                dappItem,
                (name : string)=>setNameLoad([name, true])
            ).catch((err)=>{
                console.log(err)
                dispatch((state)=>{
                    return {
                        ...state,
                        error : err
                    }
                })
            })
        }

    })
    useEffect(()=>{

        console.log(nameLoad);

        if(dappState.name !== nameLoad){
            setDappState({
                ...dappState,
                name : nameLoad
            })
        }

    })


    const [[imageUriLoad, imageUriRequested], setImageUriLoad] = useState<[
        string|undefined,
        boolean
    ]>([
        forceLoad ? undefined : dappState.appTile,
        false
    ]);
    useEffect(()=>{

        if(!imageUriLoad && !imageUriRequested){

            setImageUriLoad([undefined, true])

            getDappImageUri(
                dappItem,
                (imageUri : string)=>setImageUriLoad([imageUri, true])
            ).catch((err)=>{
                dispatch((state)=>{
                    return {
                        ...state,
                        error : err
                    }
                })
            })
        }

    })
    useEffect(()=>{

        if(dappState.appTile !== imageUriLoad){
            setDappState({
                ...dappState,
                appTile : imageUriLoad
            })
        }

    })

    const [[contract, contractRequested], setContract] = useState<[
        string| undefined,
        boolean
    ]>([
        forceLoad ? undefined : dappState.contract,
        false
    ]);
    useEffect(()=>{

        if(!contract && !contractRequested){

            setContract([undefined, true]);

            getDappSolidityContract(
                dappItem,
                (contract : string)=>setContract([contract, true])
            ).catch((err)=>{
                dispatch((state)=>{
                    return {
                        ...state,
                        error : err
                    }
                })
            })
        }

    })
    useEffect(()=>{

        if(dappState.contract !== contract){
            setDappState({
                ...dappState,
                contract : contract
            })
        }

    })

    const [[oracle, oracleRequested], setOracle] = useState<[
        DappI["oracle"],
        boolean
    ]>([
        forceLoad ? undefined : dappState.oracle,
        false
    ]);
    useEffect(()=>{

        if(!oracle && !oracleRequested){
            setOracle([undefined, true])
            getDappOracle(
                dappItem,
                (oracle : DappI["oracle"])=>setOracle([oracle, true])
            ).catch((err)=>{
                dispatch((state)=>{
                    return {
                        ...state,
                        error : err
                    }
                })
            })
        }

    })
    useEffect(()=>{

        if(dappState.oracle !== oracle){
            setDappState({
                ...dappState,
                oracle : oracle
            })
        }

    })

    const [[readme, readmeRequested], setReadme] = useState<[
        string|undefined,
        boolean
    ]>([
        forceLoad ? undefined : dappState.readme,
        false
    ]);
    useEffect(()=>{

        if(!readme && !readmeRequested){
            setReadme([undefined, true]);
            getDappReadMe(
                dappItem,
                (readme : string)=>setReadme([readme, true])
            ).catch((err)=>{
                dispatch((state)=>{
                    return {
                        ...state,
                        error : err
                    }
                })
            })
        }

    })
    useEffect(()=>{

        if(dappState.readme !== readme){
            setDappState({
                ...dappState,
                readme : readme
            })
        }

    })

    return (

        <DappMainItemInternals dappItem={dappState} style={style}/>

    )

}