import React, {FC, ReactElement} from 'react';
import MonacoEditor,{ Monaco} from '@monaco-editor/react';
import Skeleton from "react-loading-skeleton";
import { Colors, DesktopSizes } from '../../../Theme';
import { SizeDropdown } from '../../../Components/ContentBrowsing';
import { useRef } from 'react';
import { useState } from 'react';
import { ArrowsAngleExpand, ArrowsExpand, CaretDown, CaretDownFill, Github, PlayFill, X } from 'react-bootstrap-icons';
import { DappDataI, DappI } from '../Dapp';
import { Accordion } from 'react-bootstrap';
import {Dropdown} from 'react-bootstrap';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

export interface GetWidthOfText {
    (txt : string, fontname: string, fontsize : string) : number
    c : HTMLCanvasElement | undefined
    ctx : CanvasRenderingContext2D | null
}

const getWidthOfText : GetWidthOfText = (txt : string, fontname: string, fontsize : string) : number=>{
    if(getWidthOfText.c === undefined){
        getWidthOfText.c=document.createElement('canvas');
        getWidthOfText.ctx=getWidthOfText.c.getContext('2d');
    }
    var fontspec = fontsize + ' ' + fontname;
    if((getWidthOfText.ctx as any).font !== fontspec)
        (getWidthOfText.ctx as any).font = fontspec;
    return (getWidthOfText.ctx as any).measureText(txt).width;
}

getWidthOfText.c = undefined;
getWidthOfText.ctx = null;



export type DappMainItemSourceCodeProps = {
    style? : React.CSSProperties,
    code : string | undefined,
    handleDrop?: (dropped : boolean)=>void,
    handleMount?: (editor : any, monaco : Monaco)=>void
    isDropped : boolean,
    lang? : string,
    height? : number
}

export const DappMainItemSourceCode : FC<DappMainItemSourceCodeProps> = ({
    style, 
    code,
    handleDrop = ()=>{},
    handleMount = ()=>{},
    isDropped,
    lang,
    height
})=>{


    return (
        <div 
        style={{    
            width : "100%",
            height : isDropped ? "60vh" : "auto"
        }}>
            <SizeDropdown 
            onDropped={handleDrop}
            isDropped={isDropped}
            dropStyle={{
                height : "60px",
                color : "blue",
                background : "#ffffff99"
            }}
            style={{
                border : `1px solid ${Colors.Maintheme}`,
                borderRadius : DesktopSizes.BorderRadius.standard,
                paddingTop: DesktopSizes.Padding.standard,
                overflowY : "hidden",
                background : "white",
                height : "20vh"
            }}>
                {!code && <Skeleton height="100%" width="100%"/>}
                {code &&<div style={{
                    paddingLeft : "4px",
                    paddingRight : "4px",
                }}>
                    <MonacoEditor
                        width="95%"
                        onMount={handleMount}
                        height={"60vh"}
                        language={lang}
                        value={code}
                        options={{
                            scrollBeyondLastLine : false,
                            scrollbar : {
                                alwaysConsumeMouseWheel : false,
                                vertical : "hidden",
                            },
                            overviewRulerLanes: 0,
                            hideCursorInOverviewRuler: true,
                            overviewRulerBorder: false,
                            readOnly : true,
                            minimap : {
                                enabled : false
                            },
                            lineDecorationsWidth: 0,
                            lineNumbersMinChars: 0
                        }}/>
                </div>
                   }
            </SizeDropdown>
        </div>
    )

}

export const OracleItem : FC<{
    oracle : DappDataI["oracle"][number],
    setContractDropStatus?  : (dropped : boolean)=>void,
    contractDropStatus? : boolean,
    contractEditorHeight? : number,
    setModal? : (name : string)=>void,
    oracleDropStatus ?: boolean,
    setOracleDropStatus? : (status : boolean)=>void,
    oracleEditorHeight ?: number,
    setOracleEditorHeight? : (height : number)=>void,
    only ? : boolean
}> = ({
    oracle,
    contractEditorHeight,
    setModal,
    oracleEditorHeight,
    setOracleEditorHeight,
    only = false
})=>{


    const handleOracleEditorDidMount = (editor : any, monaco : Monaco)=>{

        !oracleEditorHeight && setOracleEditorHeight && setOracleEditorHeight(Math.max(
            (oracle.file.split("\n").length || 0) * 19,
            editor.getContentHeight()
        ));

    }
    const handleOracleDrop = ()=>
        setModal && setModal(oracle.fname)


    return (
        <div onClick={handleOracleDrop}>
            {only && <h6>oracle.py</h6>}
            <div style={{
                position : "relative"
            }}>
                <DappMainItemSourceCode
                    code={oracle.file}
                    lang={"python"}
                    handleMount={handleOracleEditorDidMount}
                    isDropped={false}
                    height={Math.max(contractEditorHeight||0, oracleEditorHeight||0)}
                />
            </div>
        </div>
    )
}

export const OracleDropdown : FC<{
    style ? : React.CSSProperties,
    oracles ? : DappDataI["oracle"],
    setWhich ? : (name : string)=>void,
    which : string,
    single ? : boolean
}> = ({
    style,
    oracles,
    which,
    setWhich,
    single = false
})=>{

    const toggles = oracles?.map((oracle)=>{
        return (
            <Dropdown.Item 
            onClick={()=>{
                setWhich && setWhich(oracle.fname);
            }}
            style={{
                width : "100%",
                textAlign : "left",
                background : Colors.Maintheme,
                color : "white"
            }}>
                {which === oracle.fname && <span style={{
                    color : "#c4e3bf"
                }}>&gt;</span>}&emsp;oracle.py ({oracle.fname})
            </Dropdown.Item>
        )
    })

    return (
        <Dropdown style={{
            background : "white",
            paddingTop : 0
        }}>
            <Dropdown.Toggle 
            bsPrefix="p-0"
            style={{
                width : "100%",
                textAlign : "left",
                backgroundColor : "white",
                border : "none",
                color : Colors.Maintheme
            }}>
                <h6 style={{
                    display : "inline",
                }}>oracle.py ({which})</h6>{!single && <>&emsp;<CaretDownFill/></>}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{
                width : "100%",
                background : Colors.Maintheme,
                color : "white",
            }}>
                {toggles}
            </Dropdown.Menu>
        </Dropdown>
    )

}

export type DappMainItemSourceProps = {
    style? : React.CSSProperties,
    contract ? : DappDataI["contract"],
    oracles ? : DappDataI["oracle"],
}

export const DappMainItemSource : FC<DappMainItemSourceProps>  = ({
    style,
    contract,
    oracles
}) =>{

    const isMobile = useMediaQuery({
        query: '(max-width: 840px)'
      });

    const [modal, setModal] = useState<undefined | string>(undefined);

    const [contractEditorHeight, setContractEditorHeight] = useState<number | undefined>(undefined);
    const [contractDropStatus, setContractDropStatus] = useState(false);
    const handleContractEditorDidMount = (editor : any, monaco : Monaco)=>{

        !contractEditorHeight && setContractEditorHeight(
            Math.max(
                (contract?.split("\n").length || 0) * 19,
                editor.getContentHeight()
            )
        );

    }
    const handleContractDrop = ()=>setModal("contract");
    

    const [oracleEditorHeight, setOracleEditorHeight] = useState<number | undefined>(undefined);
    const [oracleDropStatus, setOracleDropStatus] = useState(false);

    const handleModalClose =()=>setModal(undefined);
    
    const [modalDropped, setModalDropped] = useState(false);
    const handleModalDropped = (dropped : boolean)=>{
        setModalDropped(dropped);
    }


    const [whichOracle, setWhichOracle] = useState(
        oracles && oracles.length ? oracles[0].fname : undefined
    );
    useEffect(()=>{
        if(!whichOracle && oracles && oracles.length){
            setWhichOracle(oracles[0].fname)
        }
    }, [oracles]);
    const dropdownSelectedOracle = oracles?.filter((oracle)=>{
        return oracle.fname === whichOracle;
    })[0]

    const selectedOracle = oracles?.filter((oracle)=>{
        return oracle.fname === modal;
    })

    return (

        <div style={{
            ...style
        }}>
           
            <div style={{
                display : "grid",
                gridTemplateColumns : isMobile ? "1fr" : "1fr 1fr",
                gap : "5%",
                textAlign : "left"
            }}>
               <div onClick={handleContractDrop}>
               <Dropdown style={{
                    paddingTop : 0,
                    background : "white"
                }}>
                    <Dropdown.Toggle 
                    bsPrefix="p-0"
                    style={{
                        width : "100%",
                        textAlign : "left",
                        backgroundColor : "white",
                        border : "none",
                        color : Colors.Maintheme
                    }}>
                        <h6 style={{
                            display : "inline",
                        }}>contract.sol</h6>
                    </Dropdown.Toggle>
                </Dropdown>
                    <div style={{
                        position : "relative"
                    }}>
                        <DappMainItemSourceCode
                        code={contract}
                        lang={"sol"}
                        handleMount={handleContractEditorDidMount}
                        isDropped={false}
                        height={Math.max(contractEditorHeight||0, oracleEditorHeight||0)}/>
                    </div>
               </div>
               <div>
                   {whichOracle && <OracleDropdown
                    single={oracles ? oracles.length < 2 : true}
                    setWhich={setWhichOracle}
                    which={whichOracle}
                    oracles={oracles}
                   />}
                   {dropdownSelectedOracle && <OracleItem
                        oracle={dropdownSelectedOracle}
                        {...{
                            setContractDropStatus,
                            contractDropStatus,
                            contractEditorHeight : contractEditorHeight||0,
                            setModal,
                            oracleDropStatus,
                            setOracleDropStatus,
                            oracleEditorHeight : oracleEditorHeight || 0,
                            setOracleEditorHeight
                        }}/>}
                </div>
            {modal && 
                <div style={{
                    zIndex : 1000, 
                    background : `${Colors.Maintheme}77`,
                    position : "fixed",
                    height : "100vh",
                    width : "100vw",
                    padding : DesktopSizes.Padding.whitespacePreferred,
                    left : 0,
                    top : 0,
                    display : "grid",
                    justifyContent : "center",
                    justifyItems : "center",
                    overflowY : "scroll"
                }}>
                    <div style={{
                        width : "80vw",
                        height : "80vh",
                        position : "relative",
                        opacity : 1.0
                    }}>
                        <div>
                            <h5 style={{
                                display : "flex",
                                padding : DesktopSizes.Padding.standard,
                                background : `${Colors.Maintheme}ff`,
                                borderRadius : DesktopSizes.BorderRadius.standard,
                                color : "white",
                                textAlign : "left"
                            }}>{modal === "contract" ? "contract.sol" : "oracle.py"}
                                   <div 
                                        onClick={handleModalClose}
                                        style={{
                                            marginLeft: "auto",
                                            marginRight: 0,
                                            background : "white",
                                            display : "grid",
                                            gridTemplateColumns : "1fr",
                                            justifyContent : "center",
                                            justifyItems : "center",
                                            alignContent : "center",
                                            alignItems : "center",
                                            height : 24,
                                            width : 24,
                                            cursor : "pointer",
                                            color : "salmon",
                                            borderBottom: `1px solid ${Colors.Maintheme}`,
                                            borderLeft: `1px solid ${Colors.Maintheme}`,
                                            padding : "1px",
                                            borderRadius: DesktopSizes.BorderRadius.standard,
                                            opacity : .7
                                        }}>
                                            <X size={12}/>
                                        </div>
                            </h5>
                            <div style={{
                                position : "relative",
                                background : "white",
                                borderRadius: DesktopSizes.BorderRadius.standard
                            }}>
                                <DappMainItemSourceCode
                                    code={modal === "contract" ? contract : selectedOracle ? selectedOracle[0].file : ""}
                                    lang={modal === "contract" ? "sol" : "python"}
                                    handleMount={handleContractEditorDidMount}
                                    handleDrop={()=>setModal(undefined)}
                                    isDropped={true}
                                    height={modal === "contract" ? contractEditorHeight : oracleEditorHeight}
                                />
                            </div>
                        </div>
                    </div>
                </div> 
            }
        </div>
    </div>

    )

}


