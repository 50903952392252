import React, {FC, ReactElement} from 'react';
import {HashRouter, Route, Routes, useParams} from "react-router-dom";
import { IntegratedErrorBoundary } from '../Error/IntegratedErrorBoundary';
import { HomePage } from '../Pages';
import { AboutPage } from '../Pages/About/AboutPage';
import { DappPage } from '../Pages/Dapp';
import { DevPage } from '../Pages/Devs';
import { Devs } from '../Pages/Devs/Devs';
import { DocsPage } from '../Pages/Docs';
import { ErrorPage } from '../Pages/ErrorPage';
import { RunDapp } from '../Pages/RunDapp';
import { Terms } from '../Pages/Terms';

export type MainRouterProps = {}

export const MainRouter : FC<MainRouterProps>  = () =>{

    return (

        <HashRouter>
            <IntegratedErrorBoundary>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/protocol" element={<AboutPage/>}/>
                    <Route path="/dapp/:owner/:repo" element={<DappPage/>}/>
                    <Route path='/run-dapp/:owner/:repo' element={ <RunDapp/>}/>
                    <Route path="/docs" element={<DocsPage/>}/>
                    <Route path="/error" element={<ErrorPage/>}/>
                    <Route path="/devs" element={<DevPage/>}/>
                    <Route path="/terms" element={<Terms/>}/>
                </Routes>
            </IntegratedErrorBoundary>
        </HashRouter>

    )

}
