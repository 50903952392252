import React, {FC, ReactElement, useState} from 'react';
import { GrowOnEventAchamaenid } from '../../Glitter/Animations';
import { ThroughGlassAgathocles } from '../../Glitter/Animations/ThroughGlass/ThroughGlassAgathocles';

export type DiscordButtonProps = {}

export const DiscordButton : FC<DiscordButtonProps>  = () =>{

    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = ()=>{
        setHovered(true);
    }

    const handleMouseLeave = ()=>{
        setHovered(false);
    }


    return (

        <GrowOnEventAchamaenid grow={hovered}>
            <ThroughGlassAgathocles glass={hovered} glassOpacity={.8}>
            <div 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={()=>window.location.href="https://discord.gg/DmVmudrx75"}
            style={{
                display : "flex",
                alignContent : "center",
                alignItems : "center",
                cursor : "pointer",
            }}>
                <img src="/discord.png" width="30px"/>
                &ensp;
                <a href="https://discord.gg/DmVmudrx75">get in touch!</a>
            </div>
            </ThroughGlassAgathocles>
        </GrowOnEventAchamaenid>

    )



}
